import { Box, Button, Breadcrumbs, Text, Label, Select, Link } from "@primer/react"
import { Helmet } from "react-helmet"
import Header from "../../Portal/Header"
import SideNav from "../../Portal/SideNav"
import { useDispatch, useSelector } from "react-redux"
import React from "react"
import { useNavigate } from "react-router-dom"
import { UnderlineNav } from '@primer/react/drafts'
import { ArrowUpIcon, CheckIcon, DownloadIcon, FilterIcon } from "@primer/octicons-react"
import customTheme from "../../../Theme.module.scss"
import customThemVariable from "../../../ThemeVariable.module.scss"
import { ChevronRightIcon } from "@primer/octicons-react"
import { getSingleGstportal } from "../../redux/features/portalSlice"
import Papa from "papaparse";
import TaxHeroHeader from "../../Portal/TaxHeroHeader"
import { Cross2Icon } from "@radix-ui/react-icons"
function GstReturnPlaywright(props) {

    let hostName = window.location.hostname
    let host = window.location.host

    let dispatch = useDispatch()
    let navigate = useNavigate()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")

    const clientId = localStorage.getItem("client_id") || ""
    const insertId = localStorage.getItem("insert_id") || ""
    const gstIn = localStorage.getItem("gstIn") || ""

    const [periodArr, setperiodArr] = React.useState(['All', 'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'])
    const [returnTypeArr, setreturnTypeArr] = React.useState(['All', 'GSTR3B', 'GSTR-1', 'CMP08', 'GSTR2B', 'GSTR2BQ', 'GSTR2X', 'rtntype', 'GSTR7', 'ITC02', 'TRAN1', 'TRAN2', 'ITC04', 'ITC01-18B', 'GSTR6', 'ITC03-4A', 'GSTR5A', 'GSTR10', 'GSTR9', 'GSTR9C', 'GSTR4X', 'ITC01-18C'])
    const [sortbyArr, setsortbyArr] = React.useState(["Date of Filing"])

    const [selectedPeriod, setselectedPeriod] = React.useState("")
    const [selectedReturnType, setselectedReturnType] = React.useState("")
    const [selectedsortbyArr, setselectedsortbyArr] = React.useState("")

    const [items, setitems] = React.useState([{ navigation: 'Return List' }, { navigation: 'Return Files' }])
    const [selectedIndex, setSelectedIndex] = React.useState(0)

    const [showResetButton, setshowResetButton] = React.useState(false)
    const [showFilterSection, setshowFilterSection] = React.useState(false)

    const [gstData, setgstData] = React.useState(null)
    const [allDataFeilds, setallDataFeilds] = React.useState([])
    const [profileData, setProfileData] = React.useState(null)
    const [returnData, setreturnData] = React.useState([])
    const [returnDashboard,setreturnDashboard] = React.useState([])
    const [copyReturnData, setcopyReturnData] = React.useState([])

    const getGstReturnData = (props) => {

        dispatch(getSingleGstportal({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId
        })).then((data) => {

            console.log("getSingleGSTportal", data.payload)
             
            setreturnDashboard(data?.payload.data[0]?.playwright_gst_returns_dashboard)
            const allReturns = [...data?.payload.data[0]?.playwright_gst_returns].map((row,index)=>{
                 

                if(row?.dateOfFiling!==undefined&&row?.dateOfFiling!==""){

                    const parts = row?.dateOfFiling?.split('/') || "";

                    // Check if the date string has exactly 3 parts
                    if (parts.length !== 3) {
                        throw new Error('Invalid date format. Expected format: DD/MM/YYYY');
                    }
    
                    // Destructure the parts into day, month, and year
                    const [dayStr, monthStr, yearStr] = parts;
    
                    // Parse the parts into integers
                    const day = parseInt(dayStr, 10);
                    const month = parseInt(monthStr, 10) - 1; // Months are 0-based in JS
                    const year = parseInt(yearStr, 10);
    
                    // Validate the parsed numbers
                    if (
                        isNaN(day) || isNaN(month) || isNaN(year) ||
                        day < 1 || day > 31 ||
                        month < 0 || month > 11 ||
                        year < 1000 || year > 9999
                    ) {
                         
                    }
    
                    // Create and return the Date object
                   // return new Date(year, month, day);
                    return ({...row,dateOfFiling:new Date(year, month, day)})

                }else{

                  
                    return ({...row})
                }
               
            })
             console.log("allReturns",allReturns)
     
            setallDataFeilds(data?.payload?.data)
            setProfileData(data?.payload?.data[0]?.playwright_gst_profile[0])
            setreturnData(allReturns)
            setcopyReturnData(allReturns)
        })


    }

    const downloadExcelData = (props) => {

        const csv = Papa.unparse(returnData);
        const newCsvData = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        const csvData = newCsvData;
        const link = document.createElement("a");
        link.setAttribute("href", csvData);
        link.setAttribute("download", 'gstreturnlist.csv');
        link.click();

    }


    const resetAllFilters = (props) => {

        setselectedPeriod("")
        setselectedReturnType("")
        setselectedsortbyArr("")
        setshowResetButton(false)
        setreturnData(copyReturnData)


    }

    const handleClickFilterData = (props) => {

        const filteredArray = copyReturnData.filter((fData) => selectedPeriod !== "" ? selectedPeriod === "All" ? copyReturnData : fData.taxPeriod?.includes(selectedPeriod) : copyReturnData)
            .filter((fData) => selectedReturnType !== "" ? selectedReturnType === "All" ? copyReturnData : fData.returnType?.includes(selectedReturnType) : copyReturnData)
            .sort((a, b) =>
                selectedsortbyArr !== "" ? new Date(a.dateOfFiling) - new Date(b.dateOfFiling) : 0
                // selectedsortbyArr !== "" ? a.dateOfFiling - b.dateOfFiling : 0
            );

        setreturnData(filteredArray)
        setshowResetButton(true)

    }


    const ReturnListComponent = (props) => {

        return (
            <Box sx={{
                position: 'absolute',
                top: '162px',
                left: '26px',
                right: '81px',
                minHeight: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '80px'
            }}>

                <Box
                    sx={{
                        display: showFilterSection === true && selectedIndex == 0 ? 'flex' : 'none',
                        minHeight: 'auto',
                        marginBottom: '10px'
                    }}
                >
                    <Box sx={{
                        minHeight: '120px',
                        height: '120px',
                        maxHeight: '120px',
                        border: '1px solid #D0D7DE',
                        borderRadius: '3px',
                        background: '#FFFFFF',
                        display: 'flex',
                        flexDirection: 'column',
                        //   minWidth: '100%',
                        minWidth: '1257px',

                    }}>


                        <Box sx={{
                            marginTop: '22px',
                            minHeight: '28px',
                            maxHeight: '28px',
                            height: '28px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: '15px'
                        }}>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => { }}

                                >
                                    <Select.Option value={""}>FY</Select.Option>


                                </Select>
                            </Box>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >

                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {
                                        setselectedPeriod(e.target.value)
                                    }}

                                >
                                    <Select.Option value={""}
                                        selected={selectedsortbyArr === "" ? true : false}
                                    >Tax Period</Select.Option>
                                    {periodArr.map((row, index) => {
                                        return (
                                            <Select.Option value={row}
                                                selected={row === selectedPeriod}
                                            >{row}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Box>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {
                                        setselectedReturnType(e.target.value)
                                    }}

                                >
                                    <Select.Option value={""}
                                        selected={selectedReturnType === "" ? true : false}
                                    >Return Type </Select.Option>
                                    {returnTypeArr.map((row, index) => {
                                        return (
                                            <Select.Option value={row}
                                                selected={selectedReturnType === row}
                                            >{row}</Select.Option>
                                        )
                                    })}

                                </Select>
                            </Box>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => { }}

                                >
                                    <Select.Option value={""}>Include Tag</Select.Option>


                                </Select>
                            </Box>
                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => { }}

                                >
                                    <Select.Option value={""}>Exclude Tag</Select.Option>


                                </Select>
                            </Box>
                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {

                                        setselectedsortbyArr(e.target.value)

                                    }}

                                >
                                    <Select.Option value={""}
                                        selected={selectedsortbyArr === "" ? true : false}
                                    >Sort By</Select.Option>
                                    {sortbyArr.map((row, index) => {
                                        return (
                                            <Select.Option value={row}
                                                selected={selectedsortbyArr === row}
                                            >{row}</Select.Option>
                                        )
                                    })}

                                </Select>
                            </Box>




                        </Box>

                        <Box sx={{
                            marginTop: '22px',
                            minHeight: '28px',
                            maxHeight: '28px',
                            height: '28px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: '15px'
                        }}>

                            <Box sx={{
                                height: '28px',
                                minHeight: '28px',
                                width: '190px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                maxHeight: '28px',
                            }}>
                                <Button
                                    disabled={!showResetButton}
                                    variant="outline"
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        height: '28px',
                                        minHeight: '28px',
                                        width: '190px',
                                        minWidth: '190px',
                                        maxWidth: '190px',
                                        maxHeight: '28px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                    onClick={() => {
                                        resetAllFilters("")
                                    }}
                                >
                                    Reset All
                                </Button>
                            </Box>

                            <Button variant="outline" sx={{
                                background: '#0965CE',
                                color: '#ffffff',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '18px',
                                height: '28px',
                                minHeight: '28px',
                                width: '190px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                maxHeight: '28px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                                onClick={() => handleClickFilterData("")}
                            >
                                Filter data
                            </Button>





                        </Box>




                    </Box>


                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: '40px',
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                    borderColor: '#E0E0E0',
                    alignItems: 'center',

                }}>
                    <Box sx={{
                        position: 'absolute',
                        left: '0px',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '244px',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '80px'
                        }}>
                            ACCOUNT
                        </Text>
                    </Box>

                    <Box sx={{
                        position: 'absolute',
                        left: '386px',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '244px',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '220px'
                        }}>
                            PERIOD
                        </Text>
                    </Box>

                    <Box sx={{
                        position: 'absolute',
                        right: '252px',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '244px',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '220px'
                        }}>
                            RETURN
                        </Text>
                    </Box>


                    <Box sx={{
                        position: 'absolute',
                        right: '0px',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '244px',
                    }}>
                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '220px'
                        }}>
                            DATE
                        </Text>
                    </Box>




                </Box>

                {
                    returnData?.map((row, index) => {
                        return (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minHeight: '40px',
                                borderBottomWidth: 1,
                                borderBottomStyle: 'solid',
                                borderColor: '#E0E0E0',
                                alignItems: 'center',
                                background: `${customThemVariable.whiteBgColor}`,
                                ":hover": {
                                    background: 'rgba(234, 238, 242, 0.5)'
                                },
                                cursor: 'pointer'
                            }}
                                onClick={() => {
                                    // localStorage.setItem("itr_id", row.itr_id)
                                    localStorage.setItem("gstData",JSON.stringify(row))
                                    navigate('/gstexpansion')
                                }}
                            >
                                <Box sx={{
                                    position: 'absolute',
                                    left: '0px',
                                    margin: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '244px',
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '220'
                                    }}>
                                        {allDataFeilds.length > 0 ? allDataFeilds[0].pan_name :"" }
                                    </Text>
                                </Box>

                                <Box sx={{
                                    position: 'absolute',
                                    left: '386px',
                                    margin: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '244px',
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '220px'
                                    }}>
                                        {/* hexcode123_GSTR2B_202305 */}
                                        {row?.financialYear ||
                                         row?.fy
                                        + " "}
                                        {" "+row?.taxPeriod ||
                                         " "+row?.taxp
                                        }
                                    </Text>
                                </Box>

                                <Box sx={{
                                    position: 'absolute',
                                    right: '252px',
                                    margin: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '244px',
                                    alignItems: 'center'
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '74px'
                                    }}>
                                        {row.returnType}

                                    </Text>

                                    {row?.status==="Filed" ? 
                                        <Label sx={{
                                            position: 'relative',
                                            margin: '0px 27px 0px 21px',
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                            color: '#119C2B',
                                            borderRadius: '6px',
                                            minWidth: '78px',
                                            width: '78px',
                                            maxWidth: '78px',
                                            background: '#EBFFF1',
                                            border: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>

                                            <CheckIcon />
                                            <Text sx={{
                                                marginLeft: '4px'
                                            }}>
                                                Filed
                                            </Text>

                                        </Label>
                                    
                                    :
                                    row?.status==="Not Filed" ?


                                     <Label sx={{
                                            position: 'relative',
                                            margin: '0px 27px 0px 21px',
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                            color: '#881337',
                                            borderRadius: '6px',
                                            minWidth: '78px',
                                            width: '78px',
                                            maxWidth: '78px',
                                            background: '#ffe4e6',
                                            border: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>

                                            <Cross2Icon />
                                            <Text sx={{
                                                marginLeft: '4px'
                                            }}>
                                                Not Filed
                                            </Text>

                                        </Label>
                                    :
                                    ""}

                                    




                                </Box>


                                <Box sx={{
                                    position: 'absolute',
                                    right: '0px',
                                    margin: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '244px',
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '220px'
                                   
                                   }}>
                                         {row?.dateOfFiling!==undefined&&row.dateOfFiling!==""
                                          ?
                                          new Date(row?.dateOfFiling).toDateString()
                                          :
                                          ""
                                         }
                                         
                                    </Text>
                                </Box>




                            </Box>
                        )
                    })
                }





            </Box>
        )

    }


    const ReturnFilesListComponent = (props) => {

        return (
            <Box sx={{
                position: 'absolute',
                top: '162px',
                left: '26px',
                right: '81px',
                minHeight: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '80px'
            }}>

                <Box
                    sx={{
                        display: showFilterSection === true && selectedIndex == 0 ? 'flex' : 'none',
                        minHeight: 'auto',
                        marginBottom: '10px'
                    }}
                >
                    <Box sx={{
                        minHeight: '120px',
                        height: '120px',
                        maxHeight: '120px',
                        border: '1px solid #D0D7DE',
                        borderRadius: '3px',
                        background: '#FFFFFF',
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '1257px',

                    }}>


                        <Box sx={{
                            marginTop: '22px',
                            minHeight: '28px',
                            maxHeight: '28px',
                            height: '28px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: '15px'
                        }}>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => { }}

                                >
                                    <Select.Option value={""}>FY</Select.Option>


                                </Select>
                            </Box>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >

                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {
                                        setselectedPeriod(e.target.value)
                                    }}

                                >
                                    <Select.Option value={""}
                                        selected={selectedsortbyArr === "" ? true : false}
                                    >Tax Period</Select.Option>
                                    {periodArr.map((row, index) => {
                                        return (
                                            <Select.Option value={row}
                                                selected={row === selectedPeriod}
                                            >{row}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Box>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {
                                        setselectedReturnType(e.target.value)
                                    }}

                                >
                                    <Select.Option value={""}
                                        selected={selectedReturnType === "" ? true : false}
                                    >Return Type </Select.Option>
                                    {returnTypeArr.map((row, index) => {
                                        return (
                                            <Select.Option value={row}
                                                selected={selectedReturnType === row}
                                            >{row}</Select.Option>
                                        )
                                    })}

                                </Select>
                            </Box>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => { }}

                                >
                                    <Select.Option value={""}>Include Tag</Select.Option>


                                </Select>
                            </Box>
                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => { }}

                                >
                                    <Select.Option value={""}>Exclude Tag</Select.Option>


                                </Select>
                            </Box>
                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {

                                        setselectedsortbyArr(e.target.value)

                                    }}

                                >
                                    <Select.Option value={""}
                                        selected={selectedsortbyArr === "" ? true : false}
                                    >Sort By</Select.Option>
                                    {sortbyArr.map((row, index) => {
                                        return (
                                            <Select.Option value={row}
                                                selected={selectedsortbyArr === row}
                                            >{row}</Select.Option>
                                        )
                                    })}

                                </Select>
                            </Box>




                        </Box>

                        <Box sx={{
                            marginTop: '22px',
                            minHeight: '28px',
                            maxHeight: '28px',
                            height: '28px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: '15px'
                        }}>

                            <Box sx={{
                                height: '28px',
                                minHeight: '28px',
                                width: '190px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                maxHeight: '28px',
                            }}>
                                <Button
                                    disabled={!showResetButton}
                                    variant="outline"
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        height: '28px',
                                        minHeight: '28px',
                                        width: '190px',
                                        minWidth: '190px',
                                        maxWidth: '190px',
                                        maxHeight: '28px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                    onClick={() => {
                                       
                                    }}
                                >
                                    Reset All
                                </Button>
                            </Box>

                            <Button variant="outline" sx={{
                                background: '#0965CE',
                                color: '#ffffff',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '18px',
                                height: '28px',
                                minHeight: '28px',
                                width: '190px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                maxHeight: '28px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                                onClick={() =>
                                      {

                                      }
                                    //handleClickFilterData("")

                                }
                            >
                                Filter data
                            </Button>





                        </Box>




                    </Box>


                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: '40px',
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                    borderColor: '#E0E0E0',
                    alignItems: 'center',

                }}>
                    <Box sx={{
                        position: 'absolute',
                        left: '0px',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '244px',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '80px'
                        }}>
                            ACCOUNT
                        </Text>
                    </Box>

                    <Box sx={{
                        position: 'absolute',
                        left: '386px',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '100px',
                       
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '100px'
                        }}>
                            PERIOD
                        </Text>
                    </Box>

                    <Box sx={{
                        position: 'absolute',
                        right: '352px',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '244px',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '244px',
                        }}>
                            RETURN
                        </Text>
                    </Box>


                    <Box sx={{
                        position: 'absolute',
                        right: '110px',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '167px',
                    }}>
                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '167px'
                        }}>
                            DUE DATE
                        </Text>
                    </Box>

                    <Box sx={{
                        position: 'absolute',
                        right: '50px',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '60px',
                       
                    }}>
                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '60px'
                        }}>
                            File
                        </Text>
                    </Box>



                </Box>

                {
                    returnDashboard?.sort((a, b) => b.rtn_prd - a.rtn_prd).map((row, index) => {
                        return (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minHeight: '40px',
                                borderBottomWidth: 1,
                                borderBottomStyle: 'solid',
                                borderColor: '#E0E0E0',
                                alignItems: 'center',
                                background: `${customThemVariable.whiteBgColor}`,
                                ":hover": {
                                    background: 'rgba(234, 238, 242, 0.5)'
                                },
                                cursor: 'pointer'
                            }}
                                onClick={() => {
                                    // localStorage.setItem("itr_id", row.itr_id)
                                  //  localStorage.setItem("gstData",JSON.stringify(row))
                                    //  navigate('/gstexpansion')
                                }}
                            >
                                <Box sx={{
                                    position: 'absolute',
                                    left: '0px',
                                    margin: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '244px',
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '220'
                                    }}>
                                        {allDataFeilds.length > 0 ? allDataFeilds[0].pan_name :"" }
                                    </Text>
                                </Box>

                                <Box sx={{
                                    position: 'absolute',
                                    left: '386px',
                                    margin: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '100px',
                                    
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '100px',
                                    }}>
                                        {row?.rtn_prd}
                                   </Text>
                                </Box>

                                <Box sx={{
                                    position: 'absolute',
                                    right: '376px',
                                    margin: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '244px',
                                    alignItems: 'center',
                                    
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '74px',
                                       
                                    }}>
                                        {row?.return_ty}

                                    </Text>

                                    

                                        {row?.status === "FIL" ? <>
                                            <Label sx={{
                                        position: 'relative',
                                        margin: '0px 27px 0px 21px',
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        color: '#119C2B',
                                        borderRadius: '6px',
                                        minWidth: '78px',
                                        width: '78px',
                                        maxWidth: '78px',
                                        background: '#EBFFF1',
                                        border: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                            <CheckIcon />
                                            <Text sx={{
                                                marginLeft: '4px'
                                            }}>
                                                Filed
                                            </Text>
                                            </Label>
                                        </> : ""}
                                       

                                    




                                </Box>


                                <Box sx={{
                                    position: 'absolute',
                                    right: '60px',
                                    margin: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '244px',
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '220px'
                                   
                                   }}>
                                        {row?.due_dt}
                                    </Text>
                                </Box>
                                <Box sx={{
                                    position: 'absolute',
                                    right: '45px',
                                    margin: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '60px',
                                }}>
                                    {row?.returnFileUrl !== "" && row?.returnFileUrl !== null ? <>



                                        <Link
                                            sx={{
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                // color: '#252C32',
                                                position: 'relative',
                                                margin: '0px 12px 0px 12px',
                                            }}
                                            href="#"
                                            onClick={() => {
                                                window.open(row?.returnFileUrl)
                                            }}
                                        >Download</Link>
                                        
                                    </> : ""}
                                    
                                </Box>




                            </Box>
                        )
                    })
                }





            </Box>
        )

    }




    React.useState(() => {

        getGstReturnData("")

         

    })

    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })

    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? 'GST Return - taxhero' : 'GST Return  - deski'}
                </title>
            </Helmet>

            {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ?

                <><TaxHeroHeader /></>
                :
                <><Header /><SideNav /></>
            }
            

            <Box sx={{
                top: '48px',
                position: 'fixed',
                left: hostName === "www.app.taxherohq.com"|| hostName === "www.get.taxherohq.com" ? '0px' : '64px',
                right: '0px',
                minHeight: '48px',
                maxHeight: '48px',
                height: '48px',
                background: customThemVariable.whiteBgColor,
                display: 'flex',
                borderBottom: '1px solid #E5E9EB',
                zIndex: '1'
            }}>

                <Text sx={{
                    marginTop: '12px',
                    marginLeft: '32px',
                    marginBottom: '15px',
                    color: customThemVariable.textgray,
                    fontSize: '14px',
                    fontFamily: customThemVariable.primartFont,
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                    cursor: 'pointer'
                }}
                    onClick={() => {
                        hostName === "www.app.taxherohq.com"|| 
                        hostName === "www.get.taxherohq.com"
                        ?
                            navigate('/taxheroportal')
                            :
                            navigate('/portal')
                    }}
                >
                    Home
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>

                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        navigate('/gstpanlist')
                    }}
                >
                    GST
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>

                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => { }}
                >

                    {localStorage.getItem("gstpanName")}
                </Text>


                <Box sx={{
                    position: 'absolute',
                    top: '5px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '3px'
                }}>

                    <Button

                        leadingIcon={() => <Box><FilterIcon /></Box>}
                        sx={{
                            fontFamily: customThemVariable.primartFont,
                            fontSize: '14px',
                            fontStyle: customThemVariable.fontStyleNormal,
                            fontWeight: 600,
                            lineHeight: '20px',
                            paddingLeft: '13px',
                            paddingRight: '13px',
                            borderRadius: '6px',
                            color: customThemVariable.textgraydark,
                            border: 'none',
                            background: 'transparent'
                        }}
                        onClick={() => {
                            setshowFilterSection(!showFilterSection)
                        }}
                    >

                    </Button>
                    <Button
                        leadingIcon={() => <Box><DownloadIcon /></Box>}

                        sx={{
                            fontFamily: customThemVariable.primartFont,
                            fontSize: '14px',
                            fontStyle: customThemVariable.fontStyleNormal,
                            fontWeight: 600,
                            lineHeight: '20px',
                            paddingLeft: '13px',
                            paddingRight: '13px',
                            borderRadius: '6px',
                            color: customThemVariable.textgraydark,
                            border: 'none',
                            background: 'transparent'
                        }}
                        onClick={() => {
                            downloadExcelData("")
                        }}
                    >

                    </Button>



                </Box>





            </Box>


            <Box sx={{
                position: 'absolute',
                top: '48px',
                bottom: '0px',
                left: '64px',
                right: '0px',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                overflowX: 'hidden'

            }}>

                <Text sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '47px',
                    fontFamily: 'Inter',
                    fontWeight: '600',
                    fontSize: '24px',
                    lineHeight: '48px',
                    color: '#252C32'
                }}>
                    Return
                </Text>

                <UnderlineNav
                    key={"" + Math.random(100, 10000) + ""}
                    aria-label="Repository"
                    sx={{
                        position: 'absolute',
                        left: '26px',
                        top: '97px',
                        right: '81px',
                        //    width: 600,
                        //    minWidth: 600,
                        minHeight: 48,
                        maxHeight: 48,
                        height: 48,
                        fontFamily: 'Inter',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: '#252C32',
                        paddingLeft: '0px',
                        // border: 'none'
                    }} >
                    {items.map((item, index) => (

                        <UnderlineNav.Item
                            key={item.navigation}
                            aria-current={index === selectedIndex ? 'page' : undefined}
                            onSelect={e => {



                                setSelectedIndex(index)
                                e.preventDefault()


                            }}

                            selected={index === selectedIndex}
                        >
                            {item.navigation}
                        </UnderlineNav.Item>

                    ))}
                </UnderlineNav>

                <Box sx={{
                    position: 'absolute',
                    right: '81px',
                    top: '67px',
                    display: 'none',
                    flexDirection: 'row',
                    gap: '8px'
                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <Button
                            leadingIcon={() => <ArrowUpIcon />}
                            sx={{
                                width: '85px',
                                maxWidth: '85px',
                                minWidth: '85px',
                                height: '32px',
                                minHeight: '32px',
                                maxHeight: '32px',
                                borderRadius: '6px',
                                border: '1px solid #DDE2E4',
                                background: '#ffffff',
                                fontFamily: 'Inter',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '24px',
                                color: '#252C32',
                            }}

                        >
                            Filter
                        </Button>
                        <Label
                            sx={{
                                position: 'absolute',
                                top: '-8px',
                                left: '70px',
                                background: '#F76659',
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '1px 8px 1px 8px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: 'none',

                            }}

                        >
                            <Box
                                sx={{
                                    minWidth: '5px',
                                    height: '18px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    color: '#FFFFFF',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '18px',

                                }}
                            >
                                {"1"}
                            </Box>

                        </Label>
                    </Box>

                    <Button
                        leadingIcon={() => <ArrowUpIcon />}
                        sx={{
                            width: '96px',
                            maxWidth: '96px',
                            minWidth: '96px',
                            height: '32px',
                            minHeight: '32px',
                            maxHeight: '32px',
                            borderRadius: '6px',
                            border: '1px solid #DDE2E4',
                            background: '#ffffff',
                            fontFamily: 'Inter',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '24px',
                            color: '#252C32',
                        }}>
                        Export
                    </Button>
                </Box>


                {selectedIndex == 0 ?
                    <ReturnListComponent />
                    : ""}

                {selectedIndex == 1 ?
                    <ReturnFilesListComponent />
                    : ""}




            </Box>








        </Box>
    )

}

export default GstReturnPlaywright