import React from "react"
import { Box, Button, Breadcrumbs, Text, Dialog, Spinner, FormControl, Checkbox } from "@primer/react"
import { Helmet } from "react-helmet"
import EmptyData from "../../Space/EmptyData"
import Header from "../../Workplace/Header"
import SideNav from "../../Workplace/SideNav"
import { useDispatch, useSelector } from "react-redux"
import { addPanOpenClose, gstDataSyncOpenClose } from "../../redux/features/contactSlice"
import AddPanDialog from "../../Workplace/Dialogs/AddPanDialog"
import UploadPanDialog from "../../Workplace/Dialogs/UploadPanDialog"
import { useNavigate } from "react-router-dom"
import customTheme from "../../../Theme.module.scss"
import customThemVariable from "../../../ThemeVariable.module.scss"
import Workplacetheme from "../../Workplace/Workplacetheme"
import { ChevronRightIcon } from "@primer/octicons-react"
import { changeGstPasswordOpenClose, getJwtToken, getProductTourTaxhero, getSingleGstportal, updateGstPortal, updateProductTourTaxhero } from "../../redux/features/portalSlice"
import { Auth } from 'aws-amplify';
import TaxHeroHeader from "../../Portal/TaxHeroHeader"
import { Toaster, Intent, Position, ProgressBar } from "@blueprintjs/core";
import axios from "axios"
import ChangePasswordGstPanDialog from "./ChangePasswordDialog"

const synchingToast = Toaster.create({ position: Position.TOP })

function GstPlaywright(props) {

    let hostName = window.location.hostname
    let host = window.location.host
    const driver = window.driver.js.driver;

    let dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")
    const serverTrigger = useSelector((state) => state.portalData.serverTrigger)


    const clientId = localStorage.getItem("client_id") || ""
    const insertId = localStorage.getItem("insert_id") || ""
    const gstIn = localStorage.getItem("gstIn") || ""
    const initialFocusRef = React.createRef()
    const [isDisabledSyncing, setisDisabledSyncing] = React.useState(false);
    const [allDataFeilds, setallDataFeilds] = React.useState([])
    const [profileData, setProfileData] = React.useState(null)
    const [gstData, setgstData] = React.useState(null)
    const [showLoadingBtn, setshowLoadingBtn] = React.useState(false)
    const [isShowSyncDialog, setisShowSyncDialog] = React.useState(false)
    const [selectedCheckBox, setselectedCheckBox] = React.useState(["Profile", "Returns", "Notices"])
    //Post Hog Events-----------------------------------
    const postHogTrackingGSTSyncBtnClick = async (userId, userEmail) => {

        if (userId !== null && userEmail !== null) {

            const postData = {
                api_key: `${process.env.REACT_APP_POSTHOG_APIKEY_TAXHERO}`,
                event: 'GSTSyncClick',
                properties: {
                    distinct_id: `${userId}`,
                    user_id: `${userId}`,
                    user_email: `${userEmail}`,
                },
                timestamp: new Date().toISOString(),
            };

            const apiUrl = `${process.env.REACT_APP_POSTHOG_APIURL}/`

            const result = await axios.post(apiUrl, postData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return result;
        }


    }
    //------Product tour section ----------------
    const updateProductTourInDb = (props) => {

        const locationPath = [...props, { pathname: window.location.pathname, tour: true }]
        dispatch(updateProductTourTaxhero({
            userId: userId,
            userEmail: userEmail,
            locationPath: locationPath
        })).then((data) => {

            console.log("updateProductTourTaxhero", data.payload)
            const newLocationPath = data.payload.data
            console.log("newLocationPath", newLocationPath)
        })

    }
    
    const showProductTour = (props) => {

        const driverObj = driver({
            popoverClass: 'driverjs-theme',
            showProgress: true,
            allowClose: false,
            steps: [
                { element: '#gstsyncBtn', popover: { title: 'Sync data', description: `Use the sync button to update all client's latest compliance reports in the GSTIN portal.`, side: "top", align: 'start' } },
                { element: '#gstprofileDetails', popover: { title: 'GST Profile', description: `View detailed profile information about your client for quick insights.`, side: "top", align: 'start' } },

            ],
            onDestroyStarted: () => {
                console.log("driverObj", driverObj)

                if (driverObj.getActiveIndex() == 1) {
                    updateProductTourInDb(props)
                    if (!driverObj.hasNextStep() || driverObj.confirm("Are you sure?")) {
                        driverObj.destroy();

                    }

                }


            },
        });

        driverObj.drive();

    }

    const getUserProductTour = (props) => {


        dispatch(getProductTourTaxhero({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            console.log("getProductTourTaxhero", data.payload.data)
            const tourPaths = [...data.payload.data]
            const checkProductTour = tourPaths?.find((pData) => pData.pathname === window.location.pathname)?.tour
            console.log("checkProductTour", checkProductTour)
            if (!checkProductTour) {
                showProductTour(tourPaths)
            }
        })

    }



    //------------------------------------------


    const getSingleGSTportalFromDb = (props) => {

        dispatch(getSingleGstportal({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId
        })).then((data) => {

            console.log("getSingleGSTportal", data.payload)
            setallDataFeilds(data.payload.data)
            setProfileData(data?.payload?.data[0])
            setgstData(data?.payload.data[0]?.playwright_gst_profile[0])
        })

    }

    const disableSyncButton = (props) => {
        setisDisabledSyncing(true);
        setTimeout(() => {
            setisDisabledSyncing(false);
        },
            //  10000
            30 * 60 * 1000
        );
    };

    const GSTSyncDialogPlaywright = (props) => {

        return (

            <Dialog
                sx={{
                    height: '480px',
                    width: '564px',

                }}
                isOpen={isShowSyncDialog}
                onDismiss={() => {
                    setisShowSyncDialog(false)
                }}
                aria-labelledby="header-id"
                initialFocusRef={initialFocusRef}
            >
                <Dialog.Header id="header-id">
                    <Text ref={initialFocusRef} sx={Workplacetheme.scheduleDalogeHeading}>Data Sync</Text>

                </Dialog.Header>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 564,
                    height: 'fit-content',

                }}>

                    <Text sx={{
                        display: 'flex',
                        position: 'relative',
                        margin: '15px 20px 0px 15px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#6A737D',
                    }}>Once synced for a GST account, you'll have to wait for the next 8 hrs to sync again.</Text>


                    <Box sx={{
                        position: 'relative',
                        margin: '40px 40px 0px 23px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '20px'
                    }}>

                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Profile"} checked={selectedCheckBox.includes("Profile")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >Profile</FormControl.Label>
                            </FormControl>
                        </Box>

                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Returns"} checked={selectedCheckBox.includes("Returns")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >Returns</FormControl.Label>
                            </FormControl>


                        </Box>

                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Additionalnotices"} checked={selectedCheckBox.includes("Additionalnotices")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >Additional notices</FormControl.Label>
                            </FormControl>


                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Notices"} checked={selectedCheckBox.includes("Notices")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Notices</FormControl.Label>
                            </FormControl>


                        </Box>

                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl >
                                <Checkbox id="default-checkbox" value={"Demand"} checked={selectedCheckBox.includes("Demand")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Demand</FormControl.Label>
                            </FormControl>
                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl

                            >
                                <Checkbox id="default-checkbox" value={"ElectronicLedger"} checked={selectedCheckBox.includes("ElectronicLedger")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Electronic Ledger</FormControl.Label>
                            </FormControl>


                        </Box>

                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }} >
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Refund"} checked={selectedCheckBox.includes("Refund")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Refund</FormControl.Label>
                            </FormControl>


                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Challan"} checked={selectedCheckBox.includes("Challan")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Challan</FormControl.Label>
                            </FormControl>


                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Returnsfiles"} checked={selectedCheckBox.includes("Returnsfiles")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Returns files</FormControl.Label>
                            </FormControl>
                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Submission"} checked={selectedCheckBox.includes("Submission")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Submission</FormControl.Label>
                            </FormControl>
                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"TDSReturn"} checked={selectedCheckBox.includes("TDSReturn")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>TDS Return</FormControl.Label>
                            </FormControl>
                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"BankAccount"} checked={selectedCheckBox.includes("BankAccount")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Bank Account</FormControl.Label>
                            </FormControl>
                            
                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"IntimationOrder"} checked={selectedCheckBox.includes("IntimationOrder")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Intimation of Voluntary Payment - DRC-03</FormControl.Label>
                            </FormControl>
                            
                        </Box>
                    </Box>



                    <Box

                        sx={{
                            position: 'absolute',
                            bottom: '52px',
                            left: '0px',
                            right: '0px',
                        }}
                        borderColor="border.default"
                        borderBottomWidth={1}
                        borderBottomStyle="solid">


                    </Box>





                </Box>

                <Box
                    sx={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '20px',
                        right: '20px',

                    }}
                    display="flex"
                    flexDirection="row"
                    justifyContent={"right"}
                >


                    {
                        showLoadingBtn === true ?

                            <Button
                                variant="outline"
                                sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                            >
                                <Spinner sx={{
                                    position: 'absolute',
                                    left: '0px',
                                    right: '0px',
                                    margin: 'auto',
                                    top: '0px',
                                    bottom: '0px',
                                }} size="small" />
                            </Button>

                            :

                            <Button
                                style={{ width: '92px' }}
                                variant="outline"

                                sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                                onClick={() => {


                                    if (selectedCheckBox.length > 0) {
                                        showGstSyncDialog("")
                                    } else {

                                    }

                                    // showGstSyncDialog("")
                                }
                                }
                            >

                                Sync now
                            </Button>


                    }






                </Box>





            </Dialog>

        )

    }



    const showGstSyncDialog = async (props) => {

        postHogTrackingGSTSyncBtnClick(userId, userEmail)
        disableSyncButton("")
        setisShowSyncDialog(false)
        loadingToast(10, "1")
        
        try {

            const insertId = allDataFeilds[0]?.insert_id
            // const response = await fetch(`${process.env.REACT_APP_SCRAPSERVERURL}/syncgst?insertId=${insertId}`);
            const response = await fetch(`${process.env.REACT_APP_SCRAPSERVERURL}/syncgstdev?insertId=${insertId}&checkboxes=${JSON.stringify(selectedCheckBox)}`);
            setselectedCheckBox([])
          
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            const profileDataResponse = responseData?.profileData || []
            const errorCode = responseData?.code
            if (errorCode === "401") {
                // synchingToast.dismiss()
                synchingToast.clear()
                Toaster.create({ position: Position.TOP }).show({
                    message: "Sync error: Invalid username or password.",
                    intent: Intent.PRIMARY
                });
                showChangePasssordDialog(insertId, allDataFeilds[0]?.pan_name, allDataFeilds[0]?.user_name, allDataFeilds[0]?.password)
            }
            
            if (errorCode === "200") {
                loadingToast(100, "1")
            }

            // if (profileDataResponse?.length > 0) {
            //     loadingToast(100, "1")
            // }
            console.log("plinggst", responseData)
            getSingleGSTportalFromDb("")
 
        } catch (error) {


        }

    }

    const loadingToast = (progressPercentage, key) => {

        synchingToast.show({
            icon: "cloud-upload",
            message:

                <Box sx={{ marginTop: '4px' }}>
                    <ProgressBar
                        animate={true}
                        stripes={true}
                        intent={progressPercentage < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                        value={progressPercentage / 100}

                    />
                </Box>

            ,
            onDismiss: (didTimeoutExpire) => {
                if (!didTimeoutExpire) {

                    synchingToast.dismiss()

                }
            },
            timeout: progressPercentage < 100 ? 0 : 3000,

        }, key);

    }

    const showChangePasssordDialog = (props,gstName,gstUsername,gstPassword) => {

        dispatch(changeGstPasswordOpenClose({
            openCloseGstChangePassword: true,
            insertId: props,
            gstName: gstName,
            gstUsername: gstUsername,
            gstPassword: gstPassword
        }))
    }


    React.useEffect(() => {

        if (serverTrigger) {
            getSingleGSTportalFromDb("")
        }

    }, [serverTrigger])

    React.useState(() => {
        getSingleGSTportalFromDb("")
       // getUserProductTour("")
    })

    React.useState(() => {



        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })

    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? 'GST - info - taxhero' : 'GST - info - taxhero'}
                </title>
            </Helmet>
            {
                hostName === "www.app.taxherohq.com"
                    || hostName === "www.get.taxherohq.com"
                    ?

                    <><TaxHeroHeader /></>
                    :
                    <><TaxHeroHeader /></>
            }


            <Box sx={{
                top: '48px',
                position: 'fixed',
                left: hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? '0px' : '64px',
                right: '0px',
                minHeight: '48px',
                maxHeight: '48px',
                height: '48px',
                background: customThemVariable.whiteBgColor,
                display: 'flex',
                borderBottom: '1px solid #E5E9EB',
                zIndex: '1'
            }}>

                <Text sx={{
                    marginTop: '12px',
                    marginLeft: '32px',
                    marginBottom: '15px',
                    color: customThemVariable.textgray,
                    fontSize: '14px',
                    fontFamily: customThemVariable.primartFont,
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                    cursor: 'pointer'
                }}
                    onClick={() => {

                        hostName === "www.app.taxherohq.com"
                            ||
                            hostName === "www.get.taxherohq.com"
                            ?
                            navigate('/taxheroportal')
                            :
                            navigate('/portal')
                    }}
                >
                    Home
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>

                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        navigate('/gstpanlist')
                    }}
                >
                    GST
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>

                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => { }}
                >
                    {allDataFeilds[0]?.pan_name.substring(0, 100)}
                </Text>





            </Box>


            <Box
                id="gstprofileDetails"
                sx={{
                    position: 'absolute',
                    left: '93px',
                    top: '112px',
                    right: '80px',
                    minHeight: '433px',
                    background: '#FFFFFF',
                    borderRadius: '3px',
                    border: '1px solid #D0D7DE',
                    paddingBottom: '16px'
                }}>


                <Box sx={{
                    position: 'absolute',
                    top: '15px',
                    left: '25px',
                    right: '25px',
                    minHeight: '36px',
                    maxHeight: '36px',
                    height: '36px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>

                    <Text
                        className={customTheme.H3}
                        sx={{
                            color: customThemVariable.textgraydark
                        }}>
                        {gstData?.gstin}
                    </Text>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute',
                        left: '282px',
                        alignItems: 'center'
                    }}>

                        <Text
                            className={customTheme.H3}
                            sx={{
                                color: customThemVariable.textgraydark
                            }}>
                            {allDataFeilds[0]?.pan_name.substring(0, 100)}
                        </Text>

                        <Text
                            className={customTheme.H4}
                            sx={{
                                marginLeft: '30px',
                                color: customThemVariable.textgray
                            }}>
                            {allDataFeilds[0]?.user_name}
                        </Text>
                        <Text
                            className={customTheme.H4}
                            sx={{
                                marginLeft: '20px',
                                color: customThemVariable.textgray
                            }}>
                            {allDataFeilds[0]?.password}

                        </Text>

                    </Box>

                    <Text
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            right: '137px',
                            fontFamily: customThemVariable.primartFont,
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '20px',
                            fontStyle: 'normal',
                            color: customThemVariable.textgraydark

                        }}
                    >

                        Last synced on :
                        {
                            allDataFeilds.length == 0 ? "Not Synched" :
                                "" + new Date(allDataFeilds[0]?.updated_timestamp).toDateString() + " " + new Date(allDataFeilds[0]?.updated_timestamp).toLocaleTimeString() + ""
                        }


                    </Text>

                    <Button
                        id="gstsyncBtn"
                        disabled={isDisabledSyncing}
                        variant="primary"
                        sx={{
                            height: '32px',
                            width: '100px',
                            position: 'absolute',
                            right: '0px',
                            top: '0px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: customThemVariable.primartFont,
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        onClick={() => { setisShowSyncDialog(true) }}
                    >
                        SYNC
                    </Button>


                </Box>



                <Box sx={{ position: 'absolute', left: '25px', top: '67px', display: 'flex', flexDirection: 'column', gap: '10px' }}>

                   
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Legal Name
                    </Text>
                   
                   
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Primary email
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Contact
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        GSTIN System Turn over
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        PAN System Turn over
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Address
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Aggregate Turn over
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Type
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Status
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Registration Date
                    </Text>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '119px' }}>
                        <Text sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            color: '#121212',
                            minWidth: '162px'
                        }}>
                            Central Jurisdiction
                        </Text>
                        <Text sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            color: customThemVariable.textgraydark,
                            minHeight: '20px',
                        }}>
                            {gstData !== null ?
                                gstData?.centreJurisdiction?.substring(0, 500)

                                : ""}
                        </Text>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '119px' }}>
                        <Text sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            color: '#121212',
                            minWidth: '162px'
                        }}>
                            State Jurisdiction
                        </Text>
                        <Text sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            color: customThemVariable.textgraydark,
                            minHeight: '20px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            {
                                gstData !== null ?
                                    gstData?.stateJurisdiction
                                    : ""
                            }

                        </Text>

                    </Box>
                   

                </Box>

                <Box sx={{ position: 'absolute', left: '307px', top: '67px', display: 'flex', flexDirection: 'column', gap: '10px' }}>

                    <Text sx={{

                        visibility:gstData!==null? "visible":"hidden",
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {gstData?.legalName}
                        ({gstData?.constitutionOfBusiness})
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {gstData?.contactData[0]?.emailAddress}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {gstData?.contactData[0]?.mobileNumber}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {/* ₹55,43,108 (Year to date) */}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {/* ₹55,43,108 (Year to date) */}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        maxHeight: '20px',
                        height: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        
                        {gstData?.placeOfBustableData[0]?.address}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {/* Slab: Rs. 1.5 Cr. to 5 Cr. (2021-2022) */}
                        {gstData?.aggregateTurnover}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {/* Regular  */}
                        {gstData?.taxpayerType}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: gstData?.gstinStatus === "Active" ? customThemVariable.textgreen : 'red',
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {/* ACTIVE */}
                        {gstData?.gstinStatus}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {gstData !== null ?

                            gstData?.dateOfRegistration
                            : ""}


                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                    }}>
                        {/* {gstData !== null ?
                            gstData?.centreJurisdiction?.substring(0, 500)

                            : ""} */}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {/* {
                            gstData !== null ?
                                gstData?.stateJurisdiction
                                : ""
                        } */}

                    </Text>
                </Box>






            </Box>

            <AddPanDialog />
            <UploadPanDialog />
            <GSTSyncDialogPlaywright />
            <ChangePasswordGstPanDialog/>

        </Box>
    )

}

export default GstPlaywright