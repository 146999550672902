import Header from './Header'
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { Box, Text, Spinner, Heading, Select, Button } from "@primer/react"
import React from "react"
import { Auth } from 'aws-amplify';
import SignIntheme from './Authenticaton/SignIntheme';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionbyProjectsId, getUserIdInLocalDb, getUserSubscriptionData } from './redux/features/profileSlice';
import { getUserOrganizationById } from './redux/features/spaceSlice';
import axios from 'axios';


function Initialization(props) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showSurveyForm, setshowSurveyForm] = React.useState(false)
    const [userData, setUserData] = React.useState({
        userId: '',
        userEmail: ''
    })

    const [industries, setindustries] = React.useState(["Finance", "Architecture", "Sales & Marketing", "Customer service", "Product", "Engineering", "Business Administration", "Operations", "Design", "Law & Public Policy", "Human Resource", "IT", "Government", "Educator", "Other"])
    const [socialTypes, setsocialTypes] = React.useState(["Google", "Facebook", "Twitter", "Product Hunt", "Youtube", "Instagram", "Linkedin", "Online search", "Recommended ( Friend, Co-worker, Community )", "Newsletter", "Blog post"])

    const [surveryFormData, setsurveryFormData] = React.useState({
        q1: "What kind of work do you do?",
        a1: "",
        q2: "How did you discover deski?",
        a2: ""
    })

    const setUserIdInLocalDb = (props) => {

        dispatch(getUserIdInLocalDb({
            userId: props
        }))
    }

    const postHogLoginTracking = async (userId, userEmail) => {

        if (userId !== null && userEmail !== null) {

            const postData = {
                api_key: 'phc_YGL9BwpH02nYVaF1iRs3oc9fJxsk2IUyrPU07nRqVpg',
                event: 'Login',
                properties: {
                    distinct_id: `${userId}`,
                    user_id: `${userId}`,
                    user_email: `${userEmail}`,
                },
                timestamp: new Date().toISOString(),
            };

            const apiUrl = 'https://app.posthog.com/capture/';

            const result = await axios.post(apiUrl, postData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return result;
        }


    }
    
    const postHogSignInTracking = async (userId, userEmail) => {

        if (userId !== null && userEmail !== null) {

            const postData = {
                api_key: 'phc_xVkSTHqo3r9DfkSr2gZFn2JhWpgCnk9yE5VyUHp4e9v',
                event: 'Login',
                properties: {
                    distinct_id: `${userId}`,
                    user_id: `${userId}`,
                    user_email: `${userEmail}`,
                    email:`${userEmail}`,
                },
                timestamp: new Date().toISOString(),
            };

            const apiUrl = 'https://app.posthog.com/capture/';

            const result = await axios.post(apiUrl, postData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return result;
        }


    }


    const postUsersToDb = async (userId, userEmail) => {


        const firstName = localStorage.getItem("firstName") || userEmail.match(/^([^@]*)@/)[1]
        const lastName = localStorage.getItem("lastName") || ""


        var myHeaders = new Headers();
        myHeaders.append('Content-type', 'application/json')


        const urlencoded = JSON.stringify({
            "userId": userId,
            "userEmail": userEmail,
            "firstName": firstName,
            "lastName": lastName
        })



        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };


        fetch("https://api.deski.co/postUsers", requestOptions)
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {


                        console.log("post user log", json)

                        localStorage.setItem("userId", json.data[0]?.user_id)
                        localStorage.setItem("userEmail", json.data[0]?.user_email)


                        if (json.message === "User already exists") {


                            dispatch(getSubscriptionbyProjectsId({
                                userEmail: json.data[0]?.user_email
                            }))

                            dispatch(getUserSubscriptionData({
                                userEmail: json.data[0]?.user_email
                            }))


                            localStorage.setItem("userId", json.data[0]?.user_id)
                            localStorage.setItem("userEmail", json.data[0]?.user_email)
                            setUserIdInLocalDb(json.data[0]?.user_id)
                            // navigate('/workplace')
                            //----- Domain Checking code
                            const domainName = window.location.hostname;
                            console.log("window.location",window.location)
                            console.log("window.location.hostname", domainName)

                            const urls = [
                                'app.taxherohq.com',
                                'https://www.app.taxherohq.com',
                                'www.app.taxherohq.com',
                                'get.taxherohq.com','https://www.get.taxherohq.com','www.get.taxherohq.com'
                            
                              ]
                            if (urls.includes(window.location.host)) {
                                console.log("portalCheck", 'true')
                            } else {
                                console.log("portalCheck", 'false')
                            }

                           
                          //  if (domainName.match("app.deskiplatforms.com")|| domainName.match("app.taxherohq.com")) {
                            if (urls.includes(window.location.host)) {

                                navigate('/taxheroportal')
                                postHogLoginTracking(json.data[0]?.user_id,json.data[0]?.user_email)
                                
                            } else {

                                navigate('/workplace')
                                postHogSignInTracking(json.data[0]?.user_id,json.data[0]?.user_email)
                               // postHogLoginTracking(json.data[0]?.user_id,json.data[0]?.user_email)

                            }
                            //--------------------------------------
                             

                        } else if (json.message === "User sucessfully registered") {


                            setshowSurveyForm((preState) => {
                                const newState = !preState
                                return newState
                            })



                        }



                    })

                }

            })




    }

    


    const postDataToMixPanel = async (userId, userEmail) => {


        if (surveryFormData.a1 !== "" && surveryFormData.a2 !== "") {

            var myHeaders = new Headers();
            myHeaders.append('Content-type', 'application/json')


            const urlencoded = JSON.stringify({
                "userId": userId,
                "userEmail": userEmail,
                "surveyformData": surveryFormData
            })



            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            fetch("https://api.deski.co/postUserMixpanel", requestOptions)
                .then(response => {
                    if (response.ok) {
                        response.json().then(json => {



                            if (json?.data?.createUserProfileResponse.data == 1 && json?.data?.SignUpTrackResponse?.data == 1) {


                                localStorage.setItem("userId", json?.userData?.userId)
                                localStorage.setItem("userEmail", json?.userData?.userEmail)
                                setUserIdInLocalDb(json?.userData?.userId)
                               // navigate('/workplace')

                                //----- Domain Checking code
                                const domainName = window.location.hostname;
                                console.log("domainName", domainName)

                                const urls = [
                                    'app.taxherohq.com', 
                                    'https://www.app.taxherohq.com',
                                    'www.app.taxherohq.com',
                                    'get.taxherohq.com', 
                                    'https://www.get.taxherohq.com',
                                    'www.get.taxherohq.com'
                                ]
                                if (urls.includes(window.location.host)) {
                                    console.log("portalCheck", 'true')
                                } else {
                                    console.log("portalCheck", 'false')
                                }


                              //  if (domainName.match("deskiplatforms.com") || domainName.match("taxherohq.com")) {
                                if (urls.includes(window.location.host)) {

                                    navigate('/taxheroportal')

                                } else {

                                    navigate('/workplace')
                                    
                                }
                            //--------------------------------------




                            }







                        })

                    }

                })


        } else { 


        }



    }


    const emailVerificationSuccessComponent = <Box sx={SignIntheme.EmailVerificationPageBox}>
        <Text sx={SignIntheme.successpageImoge} >🎉</Text>
        <Heading sx={SignIntheme.EmailVerificationPageBoxHeading}>Your account successfully verified</Heading>

        <Text
            sx={SignIntheme.emailverificationSuccessSubpara}
            as="p">Tell us a bit about yourself.</Text>



        <Box
            sx={SignIntheme.workDropDown}
        >

            <Select
                style={SignIntheme.workDropDown.select}
                block="true"
                placeholder="What kind of work do you do?"
                onChange={(e) => {
                    setsurveryFormData((prevState) => {

                        const newState = { ...prevState, a1: e.target.value }
                        return newState

                    })
                }}
            >

                {industries.map((row, index) => {
                    return (
                        <Select.Option value={row}>{row}</Select.Option>
                    )
                })}




            </Select>

        </Box>
        <Box
            sx={SignIntheme.discoverDropDown}
        >

            <Select
                style={SignIntheme.discoverDropDown.select}
                block="true"
                placeholder="How did you discover deski?"
                onChange={(e) => {
                    setsurveryFormData((prevState) => {

                        const newState = { ...prevState, a2: e.target.value }
                        return newState

                    })
                }}
            >

                {

                    socialTypes.map((row, index) => {
                        return (
                            <Select.Option value={row}>{row}</Select.Option>
                        )
                    })
                }



            </Select>

        </Box>



        <Button
            variant="outline"
            type="button"
            aria-selected={true}
            sx={SignIntheme.continueBtn}
            onClick={() => postDataToMixPanel(userData.userId, userData.userEmail)}
        >
            <Text
                as="p"
                color="#FFFFFF"
                sx={SignIntheme.continueBtnText}

            >

                Continue to workspace
            </Text>
        </Button>




    </Box>






    React.useState(() => {


        Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {


            setUserData((prevState) => {

                const newState = {
                    userId: user.attributes.sub,
                    userEmail: user.attributes.email
                }
                return newState

            })


            postUsersToDb(user.attributes.sub, user.attributes.email)


        }).catch(error => {



        })



    })




    return (

        <Box
            position={"relative"}
            display="flex"
            height="100vh"
            width={"100vw"}
            overflow="hidden"

        >

            <Helmet>
                <title>
                    Login to continue - deski
                </title>
            </Helmet>
            <Header />

            {showSurveyForm === true ? emailVerificationSuccessComponent :


                <Box sx={{
                    height: '180px',
                    width: '236px',
                    background: '#FFFFFF',
                    border: '1px solid #D0D7DE',
                    boxShadow: '0px 8px 24px rgba(140, 149, 159, 0.2)',
                    borderRadius: '6px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                    margin: '268px auto 0px auto'
                }}>

                    <Spinner
                        size='large'
                        sx={{
                            position: 'relative',
                            margin: '34px 0px 0px 0px'
                        }}
                    />

                    <Text sx={{
                        position: 'relative',
                        margin: '18px 0px 0px 0px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontSize: '14px',
                        lineHeight: '36px',
                        fontWeight: '600',
                        color: '#24292F'
                    }}>
                        Initializing deski account
                    </Text>

                </Box>

            }







        </Box>

    )

}

export default Initialization
