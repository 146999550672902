import { ActionList, ActionMenu, Box, Button } from "@primer/react";
import TaxHeroHeaderSubComponent from "./TaxHeroHeaderSubComponent";
import workplaceTheme from "../Workplace/Workplacetheme"
import React from "react";
import { UnderlineNav } from '@primer/react/drafts'
import { useLocation, Link, useNavigate } from "react-router-dom";
const portalHeaderLogo = `${process.env.REACT_APP_S3BUCKETURL}/AccountSetup/portalLogo.svg`  

export default function TaxHeroHeader() {

    let host = window.location.host
    const domainName = window.location.hostname

    const navigate = useNavigate()
    const location = useLocation()
    const [selectedIndex, setselectedIndex] = React.useState(0)
    const [selectedItHeadIndex, setselectedItHeadIndex] = React.useState(0)

    const [items, setItems] = React.useState([
        { navigation: 'Profile', route: "/gst", isSelected: false },
        { navigation: 'Return', route: "/return", isSelected: false },
        { navigation: 'Demand', route: "/demand", isSelected: false },
        { navigation: 'Ledger', route: "/ledger", isSelected: false },
        { navigation: 'Additional Notice', route: "/additionalnotice", isSelected: false },
        { navigation: 'Notice', route: "/notice", isSelected: false },
        { navigation: 'Refund', route: "/refund", isSelected: false },
        { navigation: 'Challan', route: "/challan", isSelected: false },
        { navigation: 'TDS Return', route: "/tdsreturn", isSelected: false },
        { navigation: 'Submissions', route: "/submissions", isSelected: false },
        { navigation: 'Bank Account Details', route: "/bankaccount", isSelected: false },
     ])

    const [itHeads, setitHeads] = React.useState([
        { navigation: 'Profile', route: "/It", isSelected: false },
        { navigation: 'Return', route: "/ItReturn", isSelected: false },
        { navigation: 'Demand', route: "/ItDemand", isSelected: false },
        { navigation: 'Ledger', route: "/ItLedger", isSelected: false },
        { navigation: 'Proceeding', route: "/proceeding", isSelected: false },
        { navigation: 'Proceeding notice', route: "/proceedingNotice", isSelected: false },
        { navigation: 'Challan', route: "/ItChallan", isSelected: false },
    ])

    React.useState(() => {

        

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })


    return (
        <Box
            borderColor="border.default"
            borderWidth={1}
            borderStyle="solid"
            sx={{
                position: 'fixed',
                top: '0px',
                left: '0px',
                right: '0px',
                minHeight: '48px',
                maxHeight: '48px',
                height: '48px',
                background: '#FFFFFF',
                zIndex: '10'
            }}
        >

            <Box
                sx={{
                    display: 'flex',
                    border: 'none',
                    position: 'absolute',
                    left: 50,
                    right: 80,
                    top: 2,
                   
                }}
            >
                <img
                    style={workplaceTheme.logoStyle}
                    src={portalHeaderLogo}
                    onClick={() => { navigate('/taxheroportal') }}
                />


                   <Box

                    style={{
                        display:   
                                location.pathname === "/notice" ||
                                location.pathname === "/gst" ||
                                location.pathname === "/demand" ||
                                location.pathname === "/additionalnotice" ||
                                location.pathname === "/return" ||
                                location.pathname === "/refund" ||
                                location.pathname === "/ledger" ||
                                location.pathname === "/challan" ||
                                location.pathname === "/tdsreturn" ||
                                location.pathname === "/submissions" ||
                                location.pathname === "/bankaccount"  
                                ? "flex" : "none"
                    }}
                    sx={{
                        width: '650px',
                        minWidth: '650px',
                        height: 48,
                        minHeight: 48,
                        position: 'absolute',
                        left: '314px',
                        margin: 'auto',
                        top: 0,
                        bottom: 0,
                        fontFamily: 'Avenir LT Std',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        color: '#121212',
                       
                    }}>
                    <UnderlineNav
                        key={"" + Math.random(10, 10000) + ""}
                        sx={{
                            border: 'none',
                            position: 'absolute',
                            width: '650px',
                            maxWidth: '650px',
                            minWidth: '650px',
                            bottom: 0,
                            paddingLeft: '0px',
                            paddingRight: '0px',
                            
                        }}

                    >
                        {items.map((item, index) => (


                            <UnderlineNav.Item
                                as={Link}
                                to={item.route}
                                key={item.navigation}
                                aria-current={index === selectedIndex ? 'page' : undefined}
                                selected={item.route === location.pathname}
                                onSelect={e => {
                                    setselectedIndex(index)
                                    e.preventDefault()
                                }}
                                onClick={() => {
                                    setselectedIndex(index)
                                    navigate(item.route)
                                }}

                            >
                                {item.navigation} 
                            </UnderlineNav.Item>
                        ))}
                    </UnderlineNav>
                    {/* <ActionMenu>
                        <ActionMenu.Anchor><Button sx={{
                            position: 'absolute',
                            right: '-20px',
                            padding: '2px 8px 2px 8px',
                            top: '16px',
                            border: 'none',
                            background: 'transparent',
                            fontFamily: 'Avenir LT Std',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            color: '#121212',
                        }}>More</Button></ActionMenu.Anchor>
                        <ActionMenu.Overlay width="small" sx={{ maxWidth: '190px', margin: '0px', padding: '2px' }}>
                            <ActionList sx={{ fontFamily: 'Inter', marginLeft: '-2px', maxWidth: '180px' }}>
                                <ActionList.Item onSelect={() => {
                                    navigate('/tdsreturn')
                                }}>TDS Return</ActionList.Item>
                                <ActionList.Item
                                   onSelect={() => {
                                    navigate('/submissions')
                                   }}
                                >Submissions</ActionList.Item>
                                <ActionList.Item
                                onSelect={() => {
                                    navigate('/bankaccount')
                                   }}
                                >Bank Account Details</ActionList.Item>
                            </ActionList>
                        </ActionMenu.Overlay>
                    </ActionMenu> */}
                    
                </Box>

                
                


                <Box
                    style={{
                        display:
                            location.pathname === "/It" ||
                                location.pathname === "/ItDemand" ||
                                location.pathname === "/proceeding" ||
                                location.pathname === "/proceedingNotice" ||
                                location.pathname === "/ItReturn" ||
                                location.pathname === "/ItLedger" ||
                                location.pathname === "/ItChallan"
                                ? "flex" : "none"
                    }}
                    sx={{
                        width: '650px',
                        minWidth: '650px',
                        height: 48,
                        minHeight: 48,
                        position: 'absolute',
                        left: '314px',
                        margin: 'auto',
                        top: 0,
                        bottom: 0,
                        fontFamily: 'Avenir LT Std',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        color: '#121212',

                    }}>
                    <UnderlineNav
                        key={"" + Math.random(10, 10000) + ""}
                        sx={{
                            border: 'none',
                            position: 'absolute',
                            width: '650px',
                            maxWidth: '650px',
                            minWidth: '650px',
                            bottom: 0,
                            paddingLeft: '0px',
                            paddingRight: '0px'
                        }}

                    >
                        {itHeads.map((item, index) => (
                            <UnderlineNav.Item
                                as={Link}
                                to={item.route}
                                key={item.navigation}
                                aria-current={index === selectedItHeadIndex ? 'page' : undefined}
                                selected={item.route === location.pathname}
                                onSelect={e => {
                                    setselectedItHeadIndex(index)
                                    e.preventDefault()
                                }}
                                onClick={() => {
                                    setselectedItHeadIndex(index)
                                    navigate(item.route)
                                }}

                            >
                                {item.navigation}
                            </UnderlineNav.Item>
                        ))}
                    </UnderlineNav>
                </Box>




                <TaxHeroHeaderSubComponent />

            </Box>
        </Box>)

}